import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import Button from 'components/Button'
import DropdownMenu from 'components/common/Dropdown/DropdownMenu'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import { motion } from 'framer-motion'
import { VoteType } from 'graphql/generated'
import { useDemoVote } from 'hooks/collectives/demo/useDemoVote'
import { useMemo } from 'react'
import { TProposal } from '../ProposalsTable/ProposalsTable.types'

interface IProps {
  proposal: TProposal
}

function CollectivesDemoVoteButton({ proposal }: IProps) {
  const { demoActiveProposal, currentUser } = useCollectiveDemoContext()
  const demoVote = useDemoVote()

  const animateDemoContribute = useMemo(
    () => proposal.id === demoActiveProposal?.id && !proposal.myVote,
    [demoActiveProposal?.id, proposal.id, proposal.myVote]
  )

  return (
    <div className="grow-0 flex justify-center md:pl-2">
      {!!proposal.myVote && (
        <Button disabled color="lightgray" size="md" label="Voted" />
      )}

      {proposal.canVote && (
        <div className="pr-3">
          <motion.div
            animate={
              animateDemoContribute
                ? {
                    scale: [1, 1.15, 1]
                  }
                : undefined
            }
            transition={
              animateDemoContribute
                ? {
                    type: 'spring',
                    damping: 10,
                    stiffness: 70,
                    duration: 1.5,
                    repeat: Infinity
                  }
                : undefined
            }
          >
            <DropdownMenu
              label="Vote"
              items={[
                {
                  label: 'Vote For',
                  icon: faCheck,
                  onClick: () =>
                    demoVote(proposal.id, VoteType.FOR, currentUser)
                },
                {
                  label: `Vote Against`,
                  icon: faTimes,
                  onClick: () =>
                    demoVote(proposal.id, VoteType.AGAINST, currentUser)
                }
              ]}
            />
          </motion.div>
        </div>
      )}
    </div>
  )
}

export default CollectivesDemoVoteButton
