import React from 'react'
import Thumbnail from 'components/Thumbnail'
import { TProposal } from './ProposalsTable.types'

export default function ProposalListItemThumb({
  proposal
}: {
  proposal: TProposal
}) {
  if (proposal.collective?.thumb && proposal.collective?.name) {
    return (
      <Thumbnail
        src={proposal.collective.thumb}
        initials={proposal.collective.name.substring(0)}
        className="h-10 w-10 rounded-full overflow-hidden"
      />
    )
  }

  // special handling for "New Membership Request" prposal --
  // since those are handlded by the DAO Master, show the
  // subject's avatar instead
  if (
    proposal.origin?.__typename === 'CollectiveMembershipRequest' &&
    proposal.origin?.user?.platformUser
  ) {
    return (
      <Thumbnail
        src={proposal.origin.user.platformUser.thumb}
        initials={proposal.origin.user.platformUser.displayName?.substring(0)}
        className="h-10 w-10 rounded-full overflow-hidden"
      />
    )
  }

  const user = proposal.proposedBy?.user.platformUser
  if (user) {
    return (
      <Thumbnail
        src={user.thumb}
        initials={user.displayName?.substring(0)}
        className="h-10 w-10 rounded-full overflow-hidden"
      />
    )
  }

  return <div />
}
