import { CollectiveProposalType, VoteType } from 'graphql/generated'
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { formatTimeLabel, humanTime } from 'lib/datetime'
import Link from 'next/link'
import ProposalTallyViz from '../CollectiveProposals/ProposalTallyViz/ProposalTallyViz'
import ProposalStatusBadge from '../CollectiveProposals/ProposalStatusBadge'
import { TProposal } from './ProposalsTable.types'
import ProposalListItemThumb from './ProposalListItemThumb'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import { useCollectiveContext } from 'context/CollectiveContext'
import ModalTrigger from '../../common/ModalTrigger'
import { CollectiveCastVoteModal } from '../modals/CastVoteModal'
import { inflect } from 'inflection'
import BigNumber from 'bignumber.js'
import CollectiveUserPopover from '../Common/CollectiveUserPopover'
import Button from 'components/Button'
import CollectivesDemoVoteButton from '../CollectiveDemo/CollectivesDemoVoteButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import Typography from 'components/common/Typography'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'
import { faComment } from '@fortawesome/pro-solid-svg-icons'

interface IProps {
  proposal: TProposal
  showCollectiveName?: boolean
  threadView?: boolean
  onSelect?: CallbackWithParam<string>
}

export default function ProposalListItem({
  proposal,
  showCollectiveName = false,
  threadView = false
}: IProps) {
  const { demoMode } = useCollectiveContext()

  const [secondsLeft, setSecondsLeft] = useState(
    (moment(proposal.endTime).toDate().getTime() -
      moment().toDate().getTime()) /
      1000
  )

  const { setViewingProposal, getProposalForId } = useCollectiveDemoContext()

  const demoProposalFromCache = useMemo(() => {
    if (demoMode) {
      return getProposalForId(proposal.id)
    }
    return undefined
  }, [demoMode, getProposalForId, proposal.id])

  useEffect(() => {
    if (secondsLeft <= 0) {
      return
    }

    const timer = setInterval(() => {
      setSecondsLeft(x => x - 1)
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [secondsLeft])
  const isBotProposal = proposal.proposedBy.user.platformUser?.isBot

  const demoProposalClick = demoProposalFromCache
    ? () => setViewingProposal(demoProposalFromCache)
    : undefined

  const basicDetails = useMemo(
    () => (
      <div className="flex items-center">
        {!threadView && (
          <div className="shrink-0 h-10 w-10">
            <ProposalListItemThumb proposal={proposal} />
          </div>
        )}

        <div className={twMerge('mr-2 space-y-1', !threadView && 'ml-4')}>
          {!threadView && (
            <div className="text-sm font-medium text-gray-900">
              {proposal.title}
            </div>
          )}

          <div className="text-sm font-light text-gray-500">
            {!isBotProposal && (
              <>
                <span>{`Proposed by: `}</span>
                <CollectiveUserPopover
                  noAvatar
                  user={proposal.proposedBy.user}
                  textStyle="text-sm font-light text-gray-500"
                  asLink={false}
                />
              </>
            )}

            <span>{` \u00b7 ${humanTime(proposal.createdAt, true)}`}</span>

            {secondsLeft > 0 && (
              <span>{` \u00b7 ${formatTimeLabel(
                secondsLeft,
                true
              )} left`}</span>
            )}
          </div>

          <div className="pt-2 flex gap-2">
            <ProposalStatusBadge proposal={proposal} />

            {!threadView && proposal.thread && (
              <Typography color="lightGray">
                <FontAwesomeIcon icon={faComment} />{' '}
                {`${proposal.thread.replyCount}`}
              </Typography>
            )}
          </div>

          {showCollectiveName && !!proposal.collective?.name && (
            <div className="text-sm font-medium text-gray-900 pt-2">
              {`${proposal.collective.name
                .replace('DAO', '')
                .replace('dao', '')} DAO`}
            </div>
          )}
        </div>
      </div>
    ),
    [threadView, proposal, isBotProposal, secondsLeft, showCollectiveName]
  )

  return (
    <div
      className={twMerge(
        'hover:bg-gray-50 flex flex-col md:flex-row md:items-center',
        threadView && 'my-2 border border-gray-200 bg-white rounded-lg'
      )}
    >
      {!demoMode && !threadView ? (
        <Link
          className="basis-3/5 px-4 md:px-0 md:pl-4 pt-4 pb-2 sm:py-6 min-h-[6rem]"
          href={proposal.publicUrl}
        >
          {basicDetails}
        </Link>
      ) : (
        <div
          className={classNames(
            demoProposalFromCache && 'cursor-pointer',
            'basis-3/5 px-4 md:px-0 md:pl-4 pt-4 pb-2 sm:py-6 min-h-[6rem]'
          )}
          onClick={demoProposalClick}
        >
          {basicDetails}
        </div>
      )}

      {!demoMode && !threadView ? (
        <Link
          className="basis-2/3 px-4 md:px-0 md:pr-4 pt-4 pb-2 sm:py-6"
          href={proposal.publicUrl}
        >
          {proposal.type === CollectiveProposalType.COMMANDS ? (
            proposal.isBatch ? (
              <div />
            ) : (
              <ProposalTallyViz
                collective={proposal.collective}
                proposal={proposal}
                variant={'narrow'}
              />
            )
          ) : (
            <Typography color="gray">
              {`${proposal.totalVoters} ${inflect(
                'Answer',
                new BigNumber(proposal.totalVoters).toNumber()
              )}`}
            </Typography>
          )}
        </Link>
      ) : (
        <div
          onClick={demoProposalClick}
          className={classNames(
            demoProposalFromCache && 'cursor-pointer',
            'basis-2/3 px-4 md:px-0 md:pr-4 pt-4 pb-2 sm:py-6'
          )}
        >
          {proposal.isBatch ? (
            <div />
          ) : (
            <ProposalTallyViz
              collective={proposal.collective}
              proposal={proposal}
              variant={'narrow'}
            />
          )}
        </div>
      )}

      {demoMode ? (
        <div className="flex justify-center pb-4 md:pb-0 md:pl-2 pr-4">
          <div className="w-36 md:w-24">
            <CollectivesDemoVoteButton proposal={proposal} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center pb-4 md:pb-0 md:pl-2 pr-4">
          <div className="w-36 md:w-24">
            {proposal.canVote ? (
              <ModalTrigger
                trigger={onClick => (
                  <Button
                    onClick={onClick}
                    color="lightgray"
                    size="sm"
                    label={proposal.myVote ? 'Change Vote' : 'Vote'}
                    className="w-full"
                  />
                )}
              >
                <CollectiveCastVoteModal proposal={proposal} />
              </ModalTrigger>
            ) : !proposal.canVote && proposal.myVote ? (
              <Button
                disabled
                color="lightgray"
                size="sm"
                label={
                  proposal.myVote === VoteType.ABSTAIN
                    ? `Abstained`
                    : `Voted ${proposal.myVote}`
                }
                className="w-full"
              />
            ) : demoMode ? (
              <div
                className={classNames(
                  demoProposalFromCache && 'cursor-pointer',
                  'hidden md:flex pr-5 py-8 justify-end h-full'
                )}
                onClick={demoProposalClick}
              >
                <FontAwesomeIcon icon={faChevronRight} size="1x" />
              </div>
            ) : (
              <Link
                className="hidden md:flex pr-5 py-8 justify-end h-full"
                href={proposal.publicUrl}
              >
                <FontAwesomeIcon icon={faChevronRight} size="1x" />
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
