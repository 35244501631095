import { faPoll } from '@fortawesome/pro-duotone-svg-icons'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { formatTimeLabel, humanTime } from 'lib/datetime'
import Link from 'next/link'
import { TProposal } from './ProposalsTable.types'
import { inflect } from 'inflection'
import BigNumber from 'bignumber.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProposalSurveyQuestions } from '../CollectiveProposals/CollectiveProposalSurveyQuestions'
import { noop } from 'lodash'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import CollectiveUserPopover from '../Common/CollectiveUserPopover'
import { twMerge } from 'tailwind-merge'

interface IProps {
  proposal: TProposal
  showCollectiveName?: boolean
  threadView?: boolean
}

export default function SurveyListItem({
  proposal,
  showCollectiveName = false,
  threadView = false
}: IProps) {
  const [secondsLeft, setSecondsLeft] = useState(
    (moment(proposal.endTime).toDate().getTime() -
      moment().toDate().getTime()) /
      1000
  )

  useEffect(() => {
    if (secondsLeft <= 0) {
      return
    }

    const timer = setInterval(() => {
      setSecondsLeft(x => x - 1)
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [secondsLeft])
  const isBotProposal = proposal.proposedBy.user.platformUser?.isBot

  const metaInfo = (
    <div className="text-sm font-light text-gray-500 flex flex-row items-center">
      {proposal.proposedBy && (
        <div className="text-sm font-light text-gray-500">
          {!isBotProposal && (
            <>
              <span>{`Asked By: `}</span>
              <CollectiveUserPopover
                noAvatar
                user={proposal.proposedBy.user}
                textStyle="text-sm font-light text-gray-500"
                asLink={false}
              />
            </>
          )}

          <span>{` \u00b7 ${humanTime(proposal.createdAt, true)}`}</span>

          {secondsLeft > 0 && (
            <span>{` \u00b7 ${formatTimeLabel(secondsLeft, true)} left`}</span>
          )}

          {new BigNumber(proposal.totalVoters).gt(0) &&
            ` \u00b7 ${proposal.totalVoters} ${inflect(
              'answer',
              new BigNumber(proposal.totalVoters).toNumber()
            )}`}
        </div>
      )}
    </div>
  )

  return (
    <div
      className={twMerge(
        'hover:bg-gray-100 flex',
        threadView &&
          'bg-white hover:bg-gray-50 my-2 border border-gray-200 rounded-lg'
      )}
    >
      <div className="flex-1 p-4">
        {proposal.isShortForm ? (
          <div className="flex items-start justify-center">
            <Link className="shrink-0" href={proposal.publicUrl}>
              <FontAwesomeIcon
                icon={faPoll}
                size={'2x'}
                className="w-10 h-10"
                color={'#2172E8'}
              />
            </Link>

            <div className="ml-4 flex-1">
              <ProposalSurveyQuestions
                proposal={proposal}
                showingPage={0}
                setShowingPage={noop}
                limitedView
                listItemView
              >
                {metaInfo}
              </ProposalSurveyQuestions>

              {showCollectiveName && !!proposal.collective?.name && (
                <div className="text-sm font-medium text-gray-900 pt-2">
                  {`${proposal.collective.name
                    .replace('DAO', '')
                    .replace('dao', '')} DAO`}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Link href={proposal.publicUrl}>
            <div className="flex items-center justify-center cursor-pointer">
              <FontAwesomeIcon
                icon={faPoll}
                className="shrink-0 w-10 h-10"
                color={'#2172E8'}
              />

              <div className="ml-4 flex-1">
                <div className="text-sm font-medium text-gray-900">
                  {proposal.title}
                </div>

                <div className="pt-1.5">{metaInfo}</div>

                {showCollectiveName && !!proposal.collective?.name && (
                  <div className="text-sm font-medium text-gray-900 pt-2">
                    {`${proposal.collective.name
                      .replace('DAO', '')
                      .replace('dao', '')} DAO`}
                  </div>
                )}
              </div>
            </div>
          </Link>
        )}
      </div>
      <div className="flex flex-col">
        <Link
          className="flex justify-center items-center pl-2 md:pl-10 pr-5 md:pr-9 h-full"
          href={proposal.publicUrl}
        >
          <span>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </Link>
      </div>
    </div>
  )
}
